.common-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.app-ellipsis {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(var(--color), $alpha: 0.8);
  justify-content: center;
  align-items: center;
}

.app-form-wrapper {
  width: 100%;
  max-width: 530px;
  background-color: var(--background_default);
  // border-radius: 10px;
  // -webkit-box-shadow: 1px 2px 21px 6px rgba(0, 0, 0, 0.3);
  // -moz-box-shadow: 1px 2px 21px 6px rgba(0, 0, 0, 0.3);
  // box-shadow: 1px 2px 21px 6px rgba(0, 0, 0, 0.3);

  .form-description {
    padding: 5px 0;
    font-weight: 400;
    width: 100%;
    line-height: 25px;
    font-size: 15px;
    margin-bottom: 20px;

    a {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .server-error {
    margin-bottom: 10px;
  }

  .form-item {
    width: 100%;
    margin-bottom: 10px;

    .field-title {
      font-size: 16px;
      color: var(--title_color);
      margin-bottom: 10px;
    }

    .field-error {
      margin-top: 5px;
    }

    .forgot-password-link {
      margin-top: 10px;
      text-align: right;
    }
  }
}
